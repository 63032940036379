import React, { useEffect } from "react";
import Routes from "./router/Routes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import AnimatedCursor from "react-animated-cursor";

const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <ScrollToTop />
      <Routes />
      <AnimatedCursor
        innersize={12}
        outersize={50}
        color="174, 37, 36"
        outerAlpha={0.5}
        innerScale={0.7}
        outerScale={1}
      />
    </>
  );
};

export default App;
