import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaBehance,
} from "react-icons/fa";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/IamE1337/" },
  { Social: <FaTwitter />, link: "https://twitter.com/iamE1337" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/i_am_e1337" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/in/ernestmena/" },
  { Social: <FaBehance />, link: "https://www.behance.net/ernestmena" },
];

const Footer = () => {
  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-6 my-2">
          <div className="nav justify-content-center justify-content-md-start">
            {SocialShare.map((val, i) => (
              <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank">
                {val.Social}
              </a>
            ))}
          </div>
          {/* End .nav */}
        </div>
        {/* End .col */}

        <div className="col-md-6 my-2 text-center text-md-end">
          <p>
            © {2021} Copyright{" "}
              Ernest Mena.
            {" "}
            All right reserved
          </p>
        </div>
        {/* End .col */}
      </div>
      {/* End .row */}
    </>
  );
};

export default Footer;
