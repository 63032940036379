import React from "react";
import Skills from "../skills/Skills";

const resumeContent = [
  {
    jobPosition: `Full-stack Engineer`,
    jobType: `KELLYBRADY | Office/Remote`,
    jobDuration: `Feb 2019 - Present`,
    timeDuraton: `Full Time`,
    compnayName: "KELLYBRADY Advertising",
    jobDescription: `Build internal development infrastructure including CI/CD Pipeline w/ Bamboo, Implementing Development Standards, Project Management and Unit Testing. Build custom plugins for AWS integration in various CMS Frameworks. Develop/Deploy websites and software on various CMS Frameworks. (Laravel, WordPress, Craft CMS)`,
    delayAnimation: "",
  },
  {
    jobPosition: `Software Engineer II`,
    jobType: `eTailz | Office`,
    jobDuration: `Jul 2018 - Jan 2019`,
    timeDuraton: `Full Time`,
    compnayName: "eTailz Inc.",
    jobDescription: `Improve/Architect solutions Laravel based software. Gain knowledge in AWS systems (Aurora, Lambda, CloudFormation, etc.). Develop Angular JS software solution for business growth, monitoring, and tracking`,
    delayAnimation: "100",
  },
  {
    jobPosition: `Web Developer`,
    jobType: `Tedder Industries | Office`,
    jobDuration: `Jun 2015 - Jan 2016              
               Aug 2017 - Jun 2018`,
    timeDuraton: `Full Time`,
    compnayName: "Tedder Industries",
    jobDescription: `Develop/Maintain multiple high traffic e-commerce websites on Magento Platform. Develop/Maintain multiple WordPress websites. Develop Magento Extensions to streamline manufacturing/shipping/order processes. Build internal network infrastructure. Work closely with SEO/Marketing department to implement custom code and advertisements. Create/Maintain/Automate AWS Infrastructure.`,
    delayAnimation: "200",
  },
  {
    jobPosition: `Product Architect`,
    jobType: `Salonsense Media | Office`,
    jobDuration: `Jan 2016 - Apr 2017`,
    timeDuraton: `Full Time`,
    compnayName: "Salonsense Media",
    jobDescription: `Develop mobile application for product sales (Android, iOS). Learn React and develop internal device tracking system for thousands of Android devices. Maintain/Develop WordPress Sites and Plugins. Learn SilverStripe Framework. Social Media Marketing. SEO Strategy/Execution`,
    delayAnimation: "200",
  },
];

const educatonContent = [
  {
    passingYear: "2008-2011",
    degreeTitle: "Bachelors of Science Web Design and Interactive Media",
    instituteName: "The Art Institute of California San Diego",
  },
];

const Resume = () => {
  return (
    <>
      <section id="resume" className="section">
        <div className="container">
          <div className="title">
            <h3>Experience.</h3>
          </div>
          {/* End title */}
          <div className="resume-box">
            {resumeContent.map((val, i) => (
              <div
                className="resume-row"
                key={i}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay={val.delayAnimation}
              >
                <div className="row">
                  <div className="col-md-4 col-xl-3">
                    <div className="rb-left">
                      <h6>{val.jobPosition}</h6>
                      <label>{val.jobType}</label>
                      <p>{val.jobDuration}</p>
                      <div className="rb-time">{val.timeDuraton}</div>
                    </div>
                  </div>
                  <div className="col-md-8 col-xl-9">
                    <div className="rb-right">
                      <h6>{val.compnayName}</h6>
                      <p>{val.jobDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
                  className="mt-4 text-center"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="400"
                >
                  <a
                    className="px-btn px-btn-theme"
                    href="docs/ernest-mena-resume.pdf"
                    download
                  >
                    Download Resume for all Work Experience and Skills
                  </a>
                </div>

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>Education & Skills</h3>{" "}
          </div>

          <div className="row align-items-center">
            <div
              className="col-lg-4 m-15px-tb"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <ul className="aducation-box">
                {educatonContent.map((val, i) => (
                  <li key={i}>
                    <span>{val.passingYear}</span>
                    <h6>{val.degreeTitle} </h6>
                    <p>{val.instituteName}</p>{" "}
                  </li>
                ))}
              </ul>
            </div>
            {/* End .col */}

            <div
              className="col-lg-7 ml-auto m-15px-tb"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <Skills />
            </div>
            {/* End .col */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Resume;
