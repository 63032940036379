import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { FiLink } from "react-icons/fi";
import Masonry from "react-masonry-css";

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1,
};

const tabList = ["All", "Branding", "Photography", "Development", "Other"];
const AllPortfolioContent = [
  {
    img: "highdeology-podcast-branding-design-spokane",
    title: "Branding/Identity Design",
    subTitle: "Highdeology Podcast",
    alterText: "Highdeology Podcast - Branding",
    delayAnimation: "",
    portfolioLink: "",
  },
  {
    img: "wordpress-web-development-spokane",
    title: "WordPress Development",
    subTitle: "Highdeology Podcast",
    alterText: "Highdeology Podcast - Web Development",
    delayAnimation: "100",
    portfolioLink: "https://highdeologypodcast.com",
  },
  {
    img: "spokane-social-media-marketing",
    title: "Social Media Creative",
    subTitle: "Highdeology Podcast",
    alterText: "Highdeology Podcast - Social Media Creative",
    delayAnimation: "200",
    portfolioLink: "",
  },
  {
    img: "laravel-website-development-spokane",
    title: "Laravel Website Development",
    subTitle: "EPC Racing",
    alterText: "EPC Racing - Laravel Website Development",
    delayAnimation: "300",
    portfolioLink:
      "https://epc-racing.com",
  },
  {
    img: "logo-creation-spokane-wa",
    title: "Branding/Identity Design",
    subTitle: "EPC Racing",
    alterText: "EPC Racing - Branding/Identity Design",
    delayAnimation: "300",
    portfolioLink:
      "",
  },
  {
    img: "custom-business-card-design",
    title: "Business Card",
    subTitle: "EPC Racing",
    alterText: "EPC Racing - Business Card Creative",
    delayAnimation: "300",
    portfolioLink:
      "",
  },
  {
    img: "print-media-design-spokane",
    title: "Magazine Ad",
    subTitle: "EPC Racing",
    alterText: "EPC Racing - Print Media",
    delayAnimation: "300",
    portfolioLink:
      "",
  },
  {
    img: "video-creation-youtube",
    title: "Video",
    subTitle: "EPC Racing",
    alterText: "EPC Racing - Video",
    delayAnimation: "300",
    portfolioLink:
      "https://www.youtube.com/watch?v=X4dZDF-TQEg",
  },
  {
    img: "creative-logo-design",
    title: "Various Logo Designs",
    subTitle: "",
    alterText: "Various Logo Designs",
    delayAnimation: "300",
    portfolioLink: "",
  },
];

const BrandingPortfolioContent = [
  {
    img: "highdeology-podcast-branding-design-spokane",
    title: "Branding/Web Development",
    subTitle: "Highdeology Podcast",
    alterText: "Highdeology Podcast - Branding/Web Development",
    delayAnimation: "",
    portfolioLink: "",
  },
  {
    img: "logo-creation-spokane-wa",
    title: "Branding/Identity Design",
    subTitle: "EPC Racing",
    alterText: "EPC Racing - Branding/Identity Design",
    delayAnimation: "100",
    portfolioLink:
      "",
  },
  {
    img: "creative-logo-design",
    title: "Various Logo Designs",
    subTitle: "",
    alterText: "Various Logo Designs",
    delayAnimation: "200",
    portfolioLink: "",
  },
  {
    img: "pawsport-rescue-logo",
    title: "Branding/Web Development",
    subTitle: "Pawsport Animal Rescue",
    alterText: "Pawsport Rescue Logo Design",
    delayAnimation: "300",
    portfolioLink: "",
  },
  {
    img: "reiner-diesel-logo",
    title: "Branding",
    subTitle: "Reiner Diesel",
    alterText: "Reiner Diesel Logo",
    delayAnimation: "300",
    portfolioLink: "",
  },
];
const PhotographyPortfolioContent = [
  {
    img: "nature-photography",
    title: "Spokane Valley Nature",
    subTitle: "",
    alterText: "Spokane Valley Nature",
    delayAnimation: "",
    portfolioLink:
      "",
  },

  {
    img: "epc-camaro-1",
    title: "Camaro Photo Shoot",
    subTitle: "EPC Racing",
    alterText: "EPC Racing - Camaro Photo Shoot",
    delayAnimation: "100",
    portfolioLink:
      "",
  },
  {
    img: "epc-demon-headlight",
    title: "Dodge Demon Photo Shoot",
    subTitle: "EPC Racing",
    alterText: "EPC Racing - Dodge Demon Photo Shoot",
    delayAnimation: "200",
    portfolioLink:
      "",
  },
  {
    img: "laguna-seca-mustang",
    title: "Laguna Seca Mustang Photo Shoot",
    subTitle: "EPC Racing",
    alterText: "EPC Racing - Laguna Seca Photo Shoot",
    delayAnimation: "300",
    portfolioLink:
      "",
  },
  {
    img: "red-camaro",
    title: "Classic Camaro",
    subTitle: "EPC Racing",
    alterText: "EPC Racing - Classic Camaro",
    delayAnimation: "250",
    portfolioLink:
      "",
  },
  {
    img: "welding-action-shot",
    title: "Welding Action Shot",
    subTitle: "EPC Racing",
    alterText: "EPC Racing - Welding Action",
    delayAnimation: "350",
    portfolioLink:
      "",
  },
];

const DevelopmentPortfolioContent = [
  {
    img: "wordpress-web-development-spokane",
    title: "WordPress Development",
    subTitle: "Highdeology Podcast",
    alterText: "Highdeology Podcast - Web Development",
    delayAnimation: "0",
    portfolioLink: "https://highdeologypodcast.com",
  },
  {
    img: "rattled-n-restless-websites-design-development",
    title: "WordPress Blog Design/Development",
    subTitle: "Rattled N Restless",
    alterText: "Rattled N Restless - Web Design/Development",
    delayAnimation: "100",
    portfolioLink:
      "",
  },
  {
    img: "laravel-website-development-spokane",
    title: "Laravel Website Development",
    subTitle: "EPC Racing",
    alterText: "EPC Racing - Laravel Website Development",
    delayAnimation: "200",
    portfolioLink:
      "https://epc-racing.com",
  },
  {
    img: "boost-clothing-website-design",
    title: "Magento Website Design/Development",
    subTitle: "Boost Clothing",
    alterText: "Boost Clothing - Web Design/Development",
    delayAnimation: "300",
    portfolioLink:
      "",
  },
  {
    img: "spokane-bookkeeping-website-development",
    title: "WordPress Development",
    subTitle: "Inland Northwest Bookkeeping",
    alterText: "INW Bookkeeping - Web Design/Development",
    delayAnimation: "300",
    portfolioLink:
      "https://inwbookkeeping.com/",
  },
  {
    img: "push-menu-js-npm-library",
    title: "ES6 Push Menu Library",
    subTitle: "EM",
    alterText: "Push Menu JS - Design/Development",
    delayAnimation: "300",
    portfolioLink:
      "",
  },
];

const OtherPortfolioContent = [
  {
    img: "custom-business-card-design",
    title: "Business Card",
    subTitle: "EPC Racing",
    alterText: "EPC Racing - Business Card Creative",
    delayAnimation: "0",
    portfolioLink:
      "",
  },
  {
    img: "print-media-design-spokane",
    title: "Magazine Ad",
    subTitle: "EPC Racing",
    alterText: "EPC Racing - Print Media",
    delayAnimation: "100",
    portfolioLink:
      "",
  },
  {
    img: "video-creation-youtube",
    title: "Video",
    subTitle: "EPC Racing",
    alterText: "EPC Racing - Video",
    delayAnimation: "200",
    portfolioLink:
      "https://www.youtube.com/watch?v=X4dZDF-TQEg",
  },
];

const PortfolioAnimation = () => {
  return (
    <SimpleReactLightbox>
      <div className="portfolio-filter-01">
        <Tabs>
          <TabList className="filter d-flex flex-wrap justify-content-start">
            {tabList.map((val, i) => (
              <Tab key={i}>{val}</Tab>
            ))}
          </TabList>
          {/* End tablist */}

          <SRLWrapper>
            <TabPanel>
              <div className="portfolio-content ">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {AllPortfolioContent.map((val, i) => (
                    <div
                      className="portfolio-box-01"
                      key={i}
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay={val.delayAnimation}
                    >
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>
                            <a href={val.portfolioLink} target="_blank">
                              {val.title}
                            </a>
                          </h5>
                          <span>{val.subTitle}</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a
                          href={`img/portfolio/${val.img}.jpg`}
                          className="gallery-link"
                        >
                          <img
                            src={`img/portfolio/${val.img}.jpg`}
                            alt={val.alterText}
                          />
                        </a>
                        {/* End gallery link */}


                        <a
                          className="portfolio-icon"
                          href={val.portfolioLink}
                          target="_blank"
                        >
                          <FiLink />
                        </a>
                        {/* End .portfolio-icon */}
                      </div>
                    </div>
                  ))}
                </Masonry>
              </div>
              {/* End list wrapper */}
            </TabPanel>

            <TabPanel>
              <div className="portfolio-content">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {BrandingPortfolioContent.map((val, i) => (
                    <div
                      className="portfolio-box-01"
                      key={i}
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay={val.delayAnimation}
                    >
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>
                            <a href={val.portfolioLink} target="_blank">
                              {val.title}
                            </a>
                          </h5>
                          <span>{val.subTitle}</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a
                          href={`img/portfolio/${val.img}.jpg`}
                          className="gallery-link"
                        >
                          <img
                            src={`img/portfolio/${val.img}.jpg`}
                            alt={val.alterText}
                          />
                        </a>
                        {/* End gallery link */}

                        <a
                          className="portfolio-icon"
                          href={val.portfolioLink}
                          target="_blank"
                        >
                          <FiLink />
                        </a>
                        {/* End .portfolio-icon */}
                      </div>
                    </div>
                  ))}
                </Masonry>
                {/* grid item  */}
              </div>

              {/* End list wrapper */}
            </TabPanel>

            <TabPanel>
              <div className="portfolio-content">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {PhotographyPortfolioContent.map((val, i) => (
                    <div
                      className="portfolio-box-01"
                      key={i}
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay={val.delayAnimation}
                    >
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>
                            <a href={val.portfolioLink} target="_blank">
                              {val.title}
                            </a>
                          </h5>
                          <span>{val.subTitle}</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a
                          href={`img/portfolio/${val.img}.jpg`}
                          className="gallery-link"
                        >
                          <img
                            src={`img/portfolio/${val.img}.jpg`}
                            alt={val.alterText}
                          />
                        </a>
                        {/* End gallery link */}

                        <a
                          className="portfolio-icon"
                          href={val.portfolioLink}
                          target="_blank"
                        >
                          <FiLink />
                        </a>
                        {/* End .portfolio-icon */}
                      </div>
                    </div>
                  ))}
                </Masonry>
              </div>
              {/* End list wrapper */}
            </TabPanel>

            <TabPanel>
              <div className="portfolio-content">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {DevelopmentPortfolioContent.map((val, i) => (
                    <div
                      className="portfolio-box-01"
                      key={i}
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay={val.delayAnimation}
                    >
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>
                            <a href={val.portfolioLink} target="_blank">
                              {val.title}
                            </a>
                          </h5>
                          <span>{val.subTitle}</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a
                          href={`img/portfolio/${val.img}.jpg`}
                          className="gallery-link"
                        >
                          <img
                            src={`img/portfolio/${val.img}.jpg`}
                            alt={val.alterText}
                          />
                        </a>
                        {/* End gallery link */}

                        <a
                          className="portfolio-icon"
                          href={val.portfolioLink}
                          target="_blank"
                        >
                          <FiLink />
                        </a>
                        {/* End .portfolio-icon */}
                      </div>
                    </div>
                  ))}
                </Masonry>
              </div>
              {/* End list wrapper */}
            </TabPanel>

            <TabPanel>
              <div className="portfolio-content ">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {OtherPortfolioContent.map((val, i) => (
                    <div
                      className="portfolio-box-01"
                      key={i}
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay={val.delayAnimation}
                    >
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>
                            <a href={val.portfolioLink} target="_blank">
                              {val.title}
                            </a>
                          </h5>
                          <span>{val.subTitle}</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a
                          href={`img/portfolio/${val.img}.jpg`}
                          className="gallery-link"
                        >
                          <img
                            src={`img/portfolio/${val.img}.jpg`}
                            alt={val.alterText}
                          />
                        </a>
                        {/* End gallery link */}

                        <a
                          className="portfolio-icon"
                          href={val.portfolioLink}
                          target="_blank"
                        >
                          <FiLink />
                        </a>
                        {/* End .portfolio-icon */}
                      </div>
                    </div>
                  ))}
                </Masonry>
              </div>
              {/* End list wrapper */}
            </TabPanel>
          </SRLWrapper>
          {/* End tabpanel */}
        </Tabs>
      </div>
    </SimpleReactLightbox>
  );
};

export default PortfolioAnimation;
