import React from "react";

const ServiceContent = [
  {
    icon: "icon-mobile",
    title: "Design and Development",
    descriptions: `Mobile first designed prototypes in Adobe Xd allow for an 
    interactive experince to determine desired functionality of your website. 
    Need development on a CMS Platform, Open Source Framerwork or 
    something custom from the ground up? Over 10 years of industry experience gives me the
    experise to complete any challenge.`,
    delayAnimation: "",
  },
  {
    icon: "icon-desktop",
    title: "Custom Integrations",
    descriptions: `We live in an automated world. As technology advances, user wait less and less. Information is demanded at the snap of a finger which requires more robust, lighweight intergrated solutions. In my career, I have yet to come across any system I couldn't integrate with to achieve a goal. Complex logic and integrations is one of my specialties.`,    delayAnimation: "200",
  },
  {
    icon: "icon-target",
    title: "Digital Marketing",
    descriptions: `Building a brand is no easy task, it takes industry knowlege, time, and dedication. Many times throughout my career I have been challenged with brand growth. I have been fortunate enough to know the industries well, producing huge growth and awareness. Social media marketing is key when building an audience.`,
    delayAnimation: "400",
  },
];
export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div
            className="col-md-6 col-lg-4 my-3"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={val.delayAnimation}
          >
            <div className="feature-box-01">
              <div className="icon">
                <i className={`icon ${val.icon}`}></i>
              </div>
              <div className="feature-content">
                <h5>{val.title}</h5>
                <p>{val.descriptions}</p>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
