import React from "react";

const AwardContnet = [
  {
    img: "inc-500-award-winner",
    awardName: "Inc. 500",
    awardFor: "Fastest Growing Private Company",
    delayAnimation: "0",
  },
  {
    img: "going-above-beyond-call-duty",
    awardName: "ABCD Award",
    awardFor: "Going above and beyond the call of duty",
    delayAnimation: "200",
  },
  {
    img: "behind-the-scenes-award",
    awardName: "BTS Award",
    awardFor: "Behind the scenes development award",
    delayAnimation: "400",
  },
];

const Awards = () => {
  return (
    <>
      <div className="row">
        {AwardContnet.map((val, i) => (
          <div
            className="col-lg-4 m-15px-tb"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={val.delayAnimation}
          >
            <div className="feature-box-02 d-flex align-items-center">
              <div className="icon">
                <img src={`img/award/${val.img}.jpg`} alt="award image" />
              </div>
              <div className="media-body">
                <h6>{val.awardName}</h6>
                <p>{val.awardFor}</p>
              </div>
            </div>
          </div>
          // End .col
        ))}
      </div>
      {/* End .row */}
    </>
  );
};

export default Awards;
